import { Box, Grid, GridItem, Image, Spinner, useColorModeValue } from '@chakra-ui/react';
import Compressor from 'compressorjs';
import { useEffect, useState } from 'react';

interface Photo {
    id: number;
    src: string;
    alt: string;
    compressedSrc?: string;
}

interface GalleryProps {
    photos: Photo[];
    onPhotoClick: (photo: Photo) => void; // Passing onClick handler
}

const Gallery = ({ photos, onPhotoClick }: GalleryProps) => {
    const [compressedPhotos, setCompressedPhotos] = useState<Photo[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const compressPhotos = async (photos: Photo[]) => {
            const promises = photos.map((photo) =>
                fetch(photo.src)
                    .then((res) => res.blob())
                    .then(
                        (blob) =>
                            new Promise<string>((resolve, reject) => {
                                new Compressor(blob, {
                                    quality: 0.8,
                                    success: (compressedResult) => {
                                        resolve(URL.createObjectURL(compressedResult));
                                    },
                                    error: reject,
                                });
                            }),
                    )
                    .then((compressedSrc) => ({
                        ...photo,
                        compressedSrc,
                    }))
                    .catch((error) => {
                        console.error('Compression error for photo ID ' + photo.id + ': ', error);
                        return { ...photo }; // Return original if compression fails
                    }),
            );

            Promise.all(promises).then((compressedPhotos) => {
                setCompressedPhotos(compressedPhotos);
                setIsLoading(false);
            });
        };

        compressPhotos(photos);
    }, [photos]);

    return (
        <Box>
            {isLoading ? (
                <Spinner color={useColorModeValue('brand.500', 'brand.400')} />
            ) : (
                <Grid
                    templateColumns={{
                        base: 'repeat(1, 1fr)',
                        md: 'repeat(3, 1fr)',
                    }}
                    gap={3}
                >
                    {compressedPhotos.map((photo) => (
                        <GridItem key={photo.id}>
                            <Image
                                src={photo.compressedSrc || photo.src} // Fallback to original if compression fails
                                alt={photo.alt}
                                boxSize="100%"
                                objectFit="cover"
                                objectPosition="center center"
                                shadow={'md'}
                                onClick={() => onPhotoClick(photo)}
                                cursor={'pointer'}
                            />
                        </GridItem>
                    ))}
                </Grid>
            )}
        </Box>
    );
};

export default Gallery;
