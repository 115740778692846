import { Box, Button, IconButton, IconButtonProps, Text, useColorModeValue } from '@chakra-ui/react';
import { CZ, GB } from 'country-flag-icons/react/3x2';
import i18next from 'i18next';
import { useReload } from '../../hooks/useReload';

interface ILanguageToggleProps extends IconButtonProps {
    onLanguageToggled?: (lang: string) => void;
}

export function LanguageToggle({ onLanguageToggled: onLanguageChange, ...rest }: ILanguageToggleProps) {
    const reload = useReload();

    const changeLanguage = (lang: string) => {
        i18next.changeLanguage(lang);
        reload();
        onLanguageChange && onLanguageChange(lang);
    };

    switch (i18next.language) {
        case 'en':
            return (
                <Button
                    size="lg"
                    color={useColorModeValue('gray.900', 'gray.50')}
                    justifyContent="flex-start"
                    variant="link"
                    leftIcon={<Box w={5} as={CZ} />}
                    onClick={() => changeLanguage('cs')}
                    _focus={{
                        color: useColorModeValue('gray.900', 'gray.50'),
                    }}
                    _hover={{
                        textDecoration: 'none',
                    }}
                    {...rest}
                >
                    <Text fontSize="sm" fontWeight={'medium'}>
                        CZ
                    </Text>
                </Button>
            );

        case 'cs':
            return (
                <Button
                    size="lg"
                    color={useColorModeValue('gray.900', 'gray.50')}
                    justifyContent="flex-start"
                    variant="link"
                    leftIcon={<Box w={5} as={GB} />}
                    onClick={() => changeLanguage('en')}
                    _focus={{
                        color: useColorModeValue('gray.900', 'gray.50'),
                    }}
                    _hover={{
                        textDecoration: 'none',
                    }}
                    {...rest}
                >
                    <Text fontSize="sm" fontWeight={'medium'}>
                        EN
                    </Text>
                </Button>
            );
    }

    // This should not happen :D
    return <IconButton size="lg" variant="ghost" icon={<CZ />} p={3} onClick={() => changeLanguage('cs')} {...rest} />;
}
