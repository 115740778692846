import { HStack, HTMLChakraProps, Text } from '@chakra-ui/react';

interface ILogoProps extends HTMLChakraProps<'div'> {}

export function Logo(props: ILogoProps) {
    return (
        <HStack {...props}>
            <Text fontSize={{ base: 'xl', sm: '3xl' }} fontWeight="medium" lineHeight="1em">
                Garrigue
                <br />
                Games
            </Text>
        </HStack>
    );
}
