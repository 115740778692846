import { Button, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import { IconType } from 'react-icons';
import { Link, useLocation } from 'react-router-dom';

type PageName = '' | 'games' | 'events' | 'courses' | 'community' | 'about' | 'dungeon';

type SidebarButtonProps = {
    pageName: PageName;
    iconFill: IconType;
    iconOutline: IconType;
    text: string;
    close?: () => void;
};

export const SidebarButton = ({ pageName, iconFill, iconOutline, text, close }: SidebarButtonProps) => {
    const location = useLocation();
    const openedPage = location.pathname.split('/')[1];

    return (
        <Button
            leftIcon={
                openedPage == pageName ? (
                    <Icon as={iconFill} fontSize={'20px'} />
                ) : (
                    <Icon as={iconOutline} fontSize={'20px'} />
                )
            }
            as={Link}
            to={`/${pageName}`}
            color={
                openedPage == pageName
                    ? useColorModeValue('brand.500', 'brand.400')
                    : useColorModeValue('gray.900', 'gray.50')
            }
            variant="link"
            justifyContent="flex-start"
            _focus={{
                color:
                    openedPage == pageName
                        ? useColorModeValue('brand.500', 'brand.400')
                        : useColorModeValue('gray.900', 'gray.50'),
            }}
            _hover={{
                textDecoration: 'none',
            }}
            onClick={close}
        >
            <Text
                fontSize="xl"
                fontWeight={'medium'}
                borderBottom="solid transparent 1.5px"
                borderBottomColor={openedPage == pageName ? 'currentColor' : 'transparent'}
                _hover={{
                    borderBottomColor: 'currentColor',
                }}
            >
                {text}
            </Text>
        </Button>
    );
};
