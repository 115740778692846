import { Box, Flex, Heading, Icon, Image, Stack, Text, VStack, useColorModeValue } from '@chakra-ui/react';
import { t } from 'i18next';
import { RiAlertLine } from 'react-icons/ri';
import { useReloadVar } from '../../hooks/useReload';
import { SideBar } from '../layout/Sidebar';
import { ContactUs } from '../utils/ContactUs';
import { ErrorBoundary } from '../utils/ErrorBoundary';
import { EventButton } from '../utils/EventButton';
import { OutletOrChildren } from '../utils/OutletOrChildren';

export interface IHomePageProps {}

export function HomePage(props: IHomePageProps) {
    const { reload, value } = useReloadVar();

    return (
        <VStack gap={0}>
            <Flex
                position="absolute"
                w="100%"
                backgroundColor={useColorModeValue('brand.500', 'brand.400')}
                zIndex={10}
                h={10}
                m={0}
                alignItems={'center'}
                justifyContent={'center'}
                opacity={'90%'}
                gap={2}
            >
                <Icon as={RiAlertLine} fontSize={'20px'} color={'white'}></Icon>
                <Text color={'white'} textAlign={'center'}>
                    {t('home.WIP')}
                </Text>
            </Flex>
            <Flex
                direction={{ base: 'column', sm: 'row' }}
                w="100vw"
                h="100vh"
                bg={useColorModeValue('white', 'gray.900')}
                color={useColorModeValue('gray.900', 'gray.200')}
                key={value ? 'a' : 'b'}
            >
                <SideBar reload={reload} />
                <Box flexGrow={1} flexShrink={1} overflowX="hidden" overflowY="auto">
                    <ErrorBoundary>
                        <OutletOrChildren>
                            <Box>
                                <Box position="relative" w="100%">
                                    <Image
                                        src="/images/headings/heading2.jpg"
                                        alt="heading-about"
                                        w="100%"
                                        h={{ base: 36, sm: 36, md: 56, lg: 72 }}
                                        objectFit="cover"
                                        objectPosition={'center center'}
                                        shadow={'md'}
                                    />
                                </Box>

                                <Stack
                                    direction={{ base: 'column', md: 'row' }}
                                    maxW={1536}
                                    p={10}
                                    alignItems={'flex-start'}
                                >
                                    <VStack alignItems={'left'} maxW={1000}>
                                        <Heading
                                            fontSize="3xl"
                                            fontWeight={'semibold'}
                                            color={useColorModeValue('brand.500', 'brand.400')}
                                        >
                                            {t('home.title')}
                                        </Heading>
                                        <Text marginBottom={5} fontSize="md">
                                            {t('about.paragraph1')}
                                        </Text>
                                    </VStack>
                                    <VStack alignItems={'left'}>
                                        <Heading
                                            fontSize="xl"
                                            fontWeight={'semibold'}
                                            color={useColorModeValue('brand.500', 'brand.400')}
                                        >
                                            {t('events.upcomming')}
                                        </Heading>
                                        <EventButton
                                            link="/courses/dungeon"
                                            name="VV078 Game Development Dungeon"
                                            date="31.8.-7.9.2024"
                                            location="Dolní Morava"
                                            isInternal
                                        />
                                    </VStack>
                                </Stack>

                                <VStack maxW={1536} p={10} alignItems={'left'}>
                                    <ContactUs />
                                </VStack>
                            </Box>
                        </OutletOrChildren>
                    </ErrorBoundary>
                </Box>
            </Flex>
        </VStack>
    );
}
