import React, { useContext } from 'react';
import { API } from '../api/API';

export class Caffeine {
    public api: API;

    public constructor(api: API) {
        this.api = api;
    }
}

const caffeineContext = React.createContext<Caffeine>(undefined!);
export const CaffeineProvider = caffeineContext.Provider;

export function useApi() {
    const caffeine = useContext(caffeineContext);

    return caffeine.api;
}
