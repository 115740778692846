import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Divider,
    Heading,
    Image,
    Link,
    Stack,
    Text,
    VStack,
    useColorModeValue,
} from '@chakra-ui/react';
import { t } from 'i18next';

import { useReloadVar } from '../../hooks/useReload';
import { ContactUs } from '../utils/ContactUs';

export function Community() {
    const { reload, value } = useReloadVar();

    return (
        <Box>
            <Box position="relative" w="100%">
                <Image
                    src="/images/headings/heading2.jpg"
                    alt="heading-community"
                    w="100%"
                    h={{ base: 36, sm: 36, md: 56, lg: 72 }}
                    objectFit="cover"
                    objectPosition={'center center'}
                    shadow={'md'}
                />
                {/* original top={7} */}
                <Breadcrumb position="absolute" top={12} left={10} color={'white'}>
                    <BreadcrumbItem>
                        <BreadcrumbLink href="/">Garrigue Games</BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem fontWeight={'semibold'} isCurrentPage>
                        <BreadcrumbLink href="/community">{t('menuButtons.community')}</BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
            </Box>

            <VStack maxW={1536} p={10} alignItems={'left'}>
                <Heading fontSize="3xl" fontWeight={'semibold'} color={useColorModeValue('brand.500', 'brand.400')}>
                    {t('community.title')}
                </Heading>
                <Text marginBottom={5} fontSize="md">
                    {t('community.paragraph1')}
                </Text>
                <Stack direction="column" gap={'20px'}>
                    <Link href="https://visiongame.cz/">
                        <Stack direction="row" h="30px" alignItems={'center'} gap={'20px'}>
                            <Divider
                                orientation="vertical"
                                borderWidth={'2px'}
                                borderColor={useColorModeValue('brand.500', 'brand.400')}
                                opacity={1}
                            />

                            <Text fontSize="md" fontWeight={'semibold'}>
                                Visiongame
                            </Text>
                        </Stack>
                    </Link>
                    <Link href="https://www.gamedevarea.com/about/">
                        <Stack direction="row" h="30px" alignItems={'center'} gap={'20px'}>
                            <Divider
                                orientation="vertical"
                                borderWidth={'2px'}
                                borderColor={useColorModeValue('brand.500', 'brand.400')}
                                opacity={1}
                            />

                            <Text fontSize="md" fontWeight={'semibold'}>
                                GameDev Area
                            </Text>
                        </Stack>
                    </Link>
                    <Link href="https://gamebaze.cz/">
                        <Stack direction="row" h="30px" alignItems={'center'} gap={'20px'}>
                            <Divider
                                orientation="vertical"
                                borderWidth={'2px'}
                                borderColor={useColorModeValue('brand.500', 'brand.400')}
                                opacity={1}
                            />

                            <Text fontSize="md" fontWeight={'semibold'}>
                                Gamebaze
                            </Text>
                        </Stack>
                    </Link>
                </Stack>
                <Box paddingTop={8}>
                <ContactUs />
                </Box>
            </VStack>
        </Box>
    );
}
