import { IconType } from 'react-icons';
import { RouteObject } from 'react-router-dom';
import { About } from './components/pages/About';
import { Community } from './components/pages/Community';
import { Courses } from './components/pages/Courses';
import { Events } from './components/pages/Events';
import { HomePage } from './components/pages/Home';
import { Dungeon } from './components/pages/Dungeon';
import { Status } from './components/utils/Status';

export type SelectableIcon = {
    default: IconType;
    selected?: IconType;
};

export type AppRoute = {
    path: string;
    title: string;
    icon?: SelectableIcon;
    element: React.ReactNode;
    inMenu?: boolean;
    children?: AppRoute[];
};

export const routerConfig = (t: (id: string) => string): RouteObject[] => [
    {
        path: '/',
        element: <HomePage />,
        errorElement: <Status />,
        children: [
            // {
            //     path: ':id',
            //     element: <Subpage />,
            // }, //remove later
            {
                path: 'about',
                element: <About />,
            },
            {
                path: 'events',
                element: <Events />,
            },
            {
                path: 'community',
                element: <Community />,
            },
            {
                path: 'courses',
                element: <Courses />,
            },
            {
                path: 'courses/dungeon',
                element: <Dungeon />,
            },
            {
                path: 'dungeon',
                element: <Dungeon />,
            },
        ],
    },
];
