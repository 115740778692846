import { Button, Heading, useColorModeValue } from '@chakra-ui/react';
import { t } from 'i18next';
import { Link } from 'react-router-dom';

export function ContactUs() {
    return (
        <>
            <Heading
                paddingBottom={3}
                fontSize="md"
                fontWeight={'semibold'}
                color={useColorModeValue('brand.500', 'brand.400')}
            >
                {t('about.heading5')}
            </Heading>

            <Button variant="outline" size="sm" maxWidth={120}>
                {/* https://stackoverflow.com/questions/63782544/react-open-mailto-e-mail-client-onclick-with-body-from-textarea */}
                <Link
                    to="#"
                    onClick={(e) => {
                        window.location.href = 'mailto:kafe@fi.muni.cz';
                        e.preventDefault();
                    }}
                >
                    {t('about.paragraph6')}
                </Link>
            </Button>
        </>
    );
}
