import { Button, IconButtonProps, Text, useColorMode, useColorModeValue } from '@chakra-ui/react';
import { t } from 'i18next';
import { RiMoonLine, RiSunLine } from 'react-icons/ri';

interface IColorModeToggleProps extends IconButtonProps {
    onToggled?: (lang: 'dark' | 'light') => void;
}

export function ColorModeToggle({ onToggled, ...rest }: IColorModeToggleProps) {
    const { colorMode, toggleColorMode } = useColorMode();

    const toggle = () => {
        onToggled && onToggled(colorMode === 'light' ? 'dark' : 'light');
        toggleColorMode();
    };

    return (
        <Button
            leftIcon={colorMode === 'light' ? <RiSunLine size={'20px'} /> : <RiMoonLine size={'20px'} />}
            variant="link"
            onClick={() => toggle()}
            color={useColorModeValue('gray.900', 'gray.50')}
            justifyContent="flex-start"
            _focus={{
                color: useColorModeValue('gray.900', 'gray.50'),
            }}
            _hover={{
                textDecoration: 'none',
            }}
        >
            <Text fontSize="sm" fontWeight={'medium'}>
                {colorMode === 'light' ? t('colorMode.dark') : t('colorMode.light')}
            </Text>
        </Button>
    );
}
