import {
    Button,
    Center,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerOverlay,
    Flex,
    IconButton,
    Spacer,
    Stack,
    StackDirection,
    Text,
    VStack,
    useBreakpointValue,
    useColorModeValue,
    useDisclosure,
} from '@chakra-ui/react';
import { t } from 'i18next';
import { useRef } from 'react';
import {
    RiCalendarEventFill,
    RiCalendarEventLine,
    RiCloseFill,
    RiGlobalFill,
    RiGlobalLine,
    RiGraduationCapFill,
    RiGraduationCapLine,
    RiHome2Fill,
    RiHome2Line,
    RiInformationFill,
    RiInformationLine,
    RiMenuFill,
} from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { Brand } from '../brand/Brand';
import { Logo } from '../brand/Logo';
import { ColorModeToggle } from '../utils/ColorModeToggle';
import { LanguageToggle } from '../utils/LanguageToggle';
import { SidebarButton } from './SidebarButton';

type SideBarProps = {
    reload: () => void;
};

export const SideBar = ({ reload }: SideBarProps) => {
    const page: any = 'home';
    const { isOpen, onOpen, onClose } = useDisclosure();
    const direction = useBreakpointValue<StackDirection>({ base: 'row', sm: 'column' });
    const btnRef = useRef();

    return (
        <Stack
            direction={direction}
            paddingLeft={{ base: 0, sm: 7 }}
            paddingBottom={{ base: 0, sm: 7 }}
            paddingRight={{ base: 8, sm: 7 }}
            paddingTop={{ base: 10, sm: 16 }}
            w={{ base: 'full', sm: '60' }}
            h={{ base: '28', sm: 'full' }}
            justifyContent={{ base: 'center', sm: 'left' }}
            spacing="7"
            fontFamily={'heading'}
            flexGrow={0}
            flexShrink={0}
            borderRightWidth={1}
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            overflow={'auto'}
        >
            {direction === 'row' && (
                <>
                    <Flex alignItems="center" justifyContent="center" w="full">
                        <IconButton
                            aria-label="Menu"
                            icon={<RiMenuFill fontSize="20px" />}
                            color={useColorModeValue('gray.900', 'gray.50')}
                            onClick={onOpen}
                            _hover={{
                                textDecoration: 'none',
                            }}
                            bg={'transparent'}
                        />

                        <Spacer />

                        <Link to="/">
                            <Logo as={Link} />
                        </Link>

                        <Spacer />
                    </Flex>

                    <Drawer isOpen={isOpen} placement="left" onClose={onClose} size="xs">
                        <DrawerOverlay />
                        <DrawerContent>
                            <DrawerCloseButton>
                                <RiCloseFill fontSize={'20px'} />
                            </DrawerCloseButton>
                            <DrawerBody>
                                <VStack
                                    paddingTop={10}
                                    paddingBottom={10}
                                    paddingLeft={2}
                                    w="full"
                                    h="full"
                                    alignItems={'left'}
                                    spacing="7"
                                    fontFamily={'heading'}
                                    flexGrow={0}
                                    flexShrink={0}
                                >
                                    <SidebarButton
                                        pageName=""
                                        iconFill={RiHome2Fill}
                                        iconOutline={RiHome2Line}
                                        text={t('menuButtons.home')}
                                        close={onClose}
                                    />
                                    {/* <SidebarButton
                pageName="games"
                iconFill={RiGamepadFill}
                iconOutline={RiGamepadLine}
                text={t('menuButtons.games')}
            /> */}
                                    <SidebarButton
                                        pageName="events"
                                        iconFill={RiCalendarEventFill}
                                        iconOutline={RiCalendarEventLine}
                                        text={t('menuButtons.events')}
                                        close={onClose}
                                    />
                                    <SidebarButton
                                        pageName="courses"
                                        iconFill={RiGraduationCapFill}
                                        iconOutline={RiGraduationCapLine}
                                        text={t('menuButtons.courses')}
                                        close={onClose}
                                    />
                                    <SidebarButton
                                        pageName="community"
                                        iconFill={RiGlobalFill}
                                        iconOutline={RiGlobalLine}
                                        text={t('menuButtons.community')}
                                        close={onClose}
                                    />
                                    <SidebarButton
                                        pageName="about"
                                        iconFill={RiInformationFill}
                                        iconOutline={RiInformationLine}
                                        text={t('menuButtons.about')}
                                        close={onClose}
                                    />

                                    <Spacer />

                                    <Button
                                        as="a"
                                        href="https://kafe.fi.muni.cz"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        leftIcon={
                                            <Center fontSize="18px" w="20px">
                                                <Brand variant="simple" strokeWeight={25} stroke="currentColor" />
                                            </Center>
                                        }
                                        variant="link"
                                        color={useColorModeValue('gray.900', 'gray.50')}
                                        justifyContent="flex-start"
                                        _focus={{
                                            color: useColorModeValue('gray.900', 'gray.50'),
                                        }}
                                        _hover={{
                                            textDecoration: 'none',
                                        }}
                                    >
                                        <Text fontSize="sm" fontWeight={'medium'}>
                                            {t('menuButtons.kafe')}
                                        </Text>
                                    </Button>

                                    <ColorModeToggle aria-label="Color mode" />

                                    <LanguageToggle aria-label="Language" onLanguageToggled={() => reload()} />

                                    <Text fontSize="xs" color={useColorModeValue('gray.400', 'gray.600')}>
                                        Made by LeGTVaR with ♡.
                                        <br />© 2024
                                    </Text>
                                </VStack>
                            </DrawerBody>
                        </DrawerContent>
                    </Drawer>
                </>
            )}

            {direction === 'column' && (
                <>
                    <Link to="/">
                        <Logo as={Link} />
                    </Link>

                    <SidebarButton
                        pageName=""
                        iconFill={RiHome2Fill}
                        iconOutline={RiHome2Line}
                        text={t('menuButtons.home')}
                    />
                    {/* <SidebarButton
                        pageName="games"
                        iconFill={RiGamepadFill}
                        iconOutline={RiGamepadLine}
                        text={t('menuButtons.games')}
                    /> */}
                    <SidebarButton
                        pageName="events"
                        iconFill={RiCalendarEventFill}
                        iconOutline={RiCalendarEventLine}
                        text={t('menuButtons.events')}
                    />
                    <SidebarButton
                        pageName="courses"
                        iconFill={RiGraduationCapFill}
                        iconOutline={RiGraduationCapLine}
                        text={t('menuButtons.courses')}
                    />
                    <SidebarButton
                        pageName="community"
                        iconFill={RiGlobalFill}
                        iconOutline={RiGlobalLine}
                        text={t('menuButtons.community')}
                    />
                    <SidebarButton
                        pageName="about"
                        iconFill={RiInformationFill}
                        iconOutline={RiInformationLine}
                        text={t('menuButtons.about')}
                    />

                    <Spacer />

                    <Button
                        as="a"
                        href="https://kafe.fi.muni.cz"
                        target="_blank"
                        rel="noopener noreferrer"
                        leftIcon={
                            <Center fontSize="18px" w="20px">
                                <Brand variant="simple" strokeWeight={25} stroke="currentColor" />
                            </Center>
                        }
                        variant="link"
                        color={useColorModeValue('gray.900', 'gray.50')}
                        justifyContent="flex-start"
                        _focus={{
                            color: useColorModeValue('gray.900', 'gray.50'),
                        }}
                        _hover={{
                            textDecoration: 'none',
                        }}
                    >
                        <Text fontSize="sm" fontWeight={'medium'}>
                            {t('menuButtons.kafe')}
                        </Text>
                    </Button>

                    <ColorModeToggle aria-label="Color mode" />

                    <LanguageToggle aria-label="Language" onLanguageToggled={() => reload()} />

                    <Text fontSize="xs" color={useColorModeValue('gray.400', 'gray.600')}>
                        Made by LeGTVaR with ♡.
                        <br />© 2024
                    </Text>
                </>
            )}
        </Stack>
    );
};
