import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Button,
    Grid,
    GridItem,
    Heading,
    Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Text,
    VStack,
    useColorModeValue,
    useDisclosure,
} from '@chakra-ui/react';
import { t } from 'i18next';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useReloadVar } from '../../hooks/useReload';
import Compressor from 'compressorjs';
import Gallery from '../utils/Gallery';

type Photo = {
    id: number;
    src: string;
    alt: string;
};

const photos: Photo[] = [
    { id: 1, src: '/images/dungeon/img1.jpg', alt: 'Description 1' },
    { id: 2, src: '/images/dungeon/img2.jpg', alt: 'Description 2' },
    { id: 3, src: '/images/dungeon/dungeon-heading-2.jpg', alt: 'Description 3' },
    { id: 4, src: '/images/dungeon/img3.jpg', alt: 'Description 3' },
    { id: 5, src: '/images/dungeon/img4.jpg', alt: 'Description 4' },
    { id: 6, src: '/images/dungeon/img5.jpg', alt: 'Description 5' },
    { id: 7, src: '/images/dungeon/img6.jpg', alt: 'Description 6' },
    { id: 8, src: '/images/dungeon/img7.jpg', alt: 'Description 7' },
    { id: 9, src: '/images/dungeon/img8.jpg', alt: 'Description 8' },
];

export function Dungeon() {
    const { reload, value } = useReloadVar();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [selectedPhoto, setSelectedPhoto] = useState<Photo | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = (photo: Photo) => {
        setSelectedPhoto(photo);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedPhoto(null);
        setIsModalOpen(false);
    };

    return (
        <Box>
            <Box position="relative" w="100%">
                <Image
                    src="/images/dungeon/dungeon-heading-3.jpg"
                    alt="heading-dungeon"
                    w="100%"
                    h={{ base: 36, sm: 36, md: 56, lg: 72 }}
                    objectFit="cover"
                    objectPosition={'center center'}
                    shadow={'md'}
                />

                {/* original top={7} */}
                <Breadcrumb position="absolute" top={12} left={10} color={'white'}>
                    <BreadcrumbItem>
                        <BreadcrumbLink href="/">Garrigue Games</BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem>
                        <BreadcrumbLink href="/courses">{t('menuButtons.courses')}</BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem fontWeight={'semibold'} isCurrentPage>
                        <BreadcrumbLink href="/courses/dungeon">{t('dungeon.title')}</BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
            </Box>

            <VStack maxW={1536} p={10} alignItems={'left'}>
                <Heading fontSize="3xl" fontWeight={'semibold'} color={useColorModeValue('brand.500', 'brand.400')}>
                    {t('dungeon.title')}
                </Heading>
                <Text marginBottom={5} fontSize="md">
                    {t('dungeon.paragraph1')}
                </Text>
                <Text fontSize="md">
                    {t('dungeon.prebutton')}
                    <Text textStyle="link" as="span">
                        <Link
                            to="https://docs.google.com/spreadsheets/d/160Ryq2sfYvQ5ToLRykK556ooTpnbTX62vgmz3XM6k8A/edit?usp=sharing"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {' '}
                            Harmonogram 2023.
                        </Link>
                    </Text>
                </Text>
                <Text fontSize="md">{t('dungeon.paragraph2')}</Text>
                <Text marginBottom={5} fontSize="md">
                    {t('dungeon.paragraph3')}
                </Text>

                <Button variant="outline" size="sm" maxWidth={220} marginBottom={5}>
                    <Link to="https://is.muni.cz/auth/predmet/fi/VV078">{t('dungeon.syllabus')}</Link>
                </Button>
                <Heading fontSize="2xl" fontWeight={'medium'} color={useColorModeValue('brand.500', 'brand.400')}>
                    {t('dungeon.basicinfoheading')}
                </Heading>
                <Text fontSize="sm" fontWeight="semibold">
                    {t('dungeon.date')}
                    <Text as="span" fontSize="sm" fontWeight="normal">
                        {t('dungeon.basicinfodate')}
                    </Text>
                </Text>
                <Text fontSize="sm" fontWeight="semibold">
                    {t('dungeon.place')}
                    <Text as="span" fontSize="sm" fontWeight="normal">
                        {t('dungeon.basicinfoplace')}
                        <Link to="http://navysluni-dm.cz/" target="_blank" rel="noopener noreferrer">
                            {' '}
                            (http://navysluni-dm.cz/)
                        </Link>
                    </Text>
                </Text>
                <Text fontSize="sm" fontWeight="semibold">
                    {t('dungeon.transport')}
                    <Text as="span" fontSize="sm" fontWeight="normal">
                        {t('dungeon.basicinfotransport')}
                    </Text>
                </Text>
                <Text fontSize="sm" fontWeight="semibold" marginBottom={5}>
                    {t('dungeon.costs')}
                    <Text as="span" fontSize="sm" fontWeight="normal">
                        {t('dungeon.basicinfocosts')}
                    </Text>
                </Text>

                <Heading fontSize="2xl" fontWeight={'medium'} color={useColorModeValue('brand.500', 'brand.400')}>
                    {t('dungeon.photosheading')}
                </Heading>

                <Gallery photos={photos} onPhotoClick={openModal} />
            </VStack>

            <Modal isOpen={isModalOpen} onClose={closeModal} size={'3xl'} isCentered>
                <ModalOverlay />
                <ModalContent m={0} borderRadius="none">
                    <ModalCloseButton
                        size="lg"
                        _hover={{ color: 'black' }}
                        zIndex="1"
                        position="absolute"
                        top="4"
                        right="4"
                    />
                    <ModalBody p={0}>
                        {selectedPhoto && (
                            <Image
                                key={selectedPhoto?.id}
                                src={selectedPhoto?.src}
                                alt={selectedPhoto?.alt}
                                boxSize="100%"
                                width="100vw"
                                height="100%"
                                objectFit="cover"
                                objectPosition="center"
                                shadow="md"
                                cursor="pointer"
                            />
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    );
}
